import { useEffect } from 'react'
import { useRouter } from 'next/router'
export interface pagePayload {
  partner: {
    logoPath: string
    productImagePath: string
    productName: string
  }
  instructions: string
  progressBarIconName: string
  advantages: Array<{
    icon: string
    label: string
    text: string
  }>
}

const Home = () => {
  const router = useRouter()
  useEffect(() => {
    router.push('https://www.plusdin.com.br')
  }, [])

  return <></>
}

export default Home
